import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import {useEffect, useState} from "react";

export default function Reviews() {
    const { t } = useTranslation();
    const [isMobile, setIsMobile] = useState(typeof window !== "undefined" && window.innerWidth < 768);
    const [hideReviewOnMobile, setHideReviewOnMobile] = useState(true);
    const reviews = [
        {
            name: "reviewsAnna",
            text: "Заказывали у ребят сайт флористического агентства. Всё сделали чётко в оговоренный срок и превзошли наши ожидания. Большое спасибо за сайт!"
        },
        {
            name: "reviewsVasiliy",
            text: "AVZpro нам делали лэндинг, справились хорошо. Ребята помогли поднять интерес к нашим продуктам при помощи нового сайта."
        },
        {
            name: "reviewsSergey",
            text: "Заказывали у AVZpro сайт компании. Сайт сделали довольно хорошо и уложились в оговоренные сроки."
        },
        {
            name: "reviewsGennady",
            text: "О компании узнал через знакомых, заказывал у них лэндинг на нескольких языках. Все мои требованния учли, я доволен."
        },
        {
            name: "reviewsEvgeniya",
            text: "Заказывали в AVZpro статьи для продвижения сайта в поисковиках. Работой студии довольны, текста написали качественно и в своответствии с нашим ТЗ."
        }
    ];

    useEffect(() => {
        const mobileCheck = () => {
            setIsMobile(window.innerWidth < 768);
        };

        window.addEventListener('resize', mobileCheck);
        window.addEventListener('orientationchange', mobileCheck);

        return () => {
            window.removeEventListener('resize', mobileCheck);
            window.removeEventListener('orientationchange', mobileCheck);
        }
    }, []); // Will fire only once

    return (
        <Container fluid className="reviews px-flex position-relative">
            <div className="reviews__background grad position-absolute w-100 h-100" />
            <div className="d-inline-block my-3 my-xl-4 my-xxl-5 py-2 py-md-3">
                <Row>
                    <Col xs={6} md={4} className="my-2 my-xl-3 my-xxl-4">
                        <h2 className="h1">{t('reviewsAbout')} AVZpro</h2>
                        <p>{reviews.length} {t('reviewsAboutWebStudioWork')}</p>
                    </Col>
                    {reviews.map((item, index) => {
                        return (
                            <Col xs={6} md={4} key={`technology-${index}`} className={`my-2 my-xl-3 my-xxl-4 ${index > 2 && isMobile && hideReviewOnMobile ? 'd-none' : ''}`}>
                                <div className={`reviews__item p-3 p-xl-4 p-xxl-5 d-flex flex-wrap justify-content-between flex-column h-100 `}>
                                    <div className="mb-2 mb-xl-3 pb-1 px-xl-2 px-xxl-3 pt-xl-2 pt-xxl-3">{item.text}</div>
                                    {item.name &&
                                        <p className="reviews__item-name h5 text-uppercase opacity-50 mb-0 px-xl-2 px-xxl-3 pb-xl-2 pb-xxl-3">{t(item.name)}</p>
                                    }
                                </div>
                            </Col>
                        )
                    })}
                </Row>
                {hideReviewOnMobile &&
                    <div className="d-md-none d-flex justify-content-center my-2 my-xl-3">
                        <div className="btn__big pointer text-nowrap" onClick={() => setHideReviewOnMobile(!hideReviewOnMobile)}>{t('showMore')}</div>
                    </div>
                }
            </div>
        </Container>
    )
}