import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import {withPrefix} from "gatsby";

export default function SupportBlock({icon, head, text, orientation}) {
    const { t } = useTranslation();

    return (
        <section className="info-block gray-bg">
            <Container fluid className="px-flex position-relative d-inline-block">
                <div className="my-3 my-xl-4 my-xxl-5 py-2">
                    <Row className={`${orientation === 'right' ? 'flex-row-reverse' : ''}`}>
                        <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mt-3 mt-md-0 pt-1 pt-md-0">
                            <svg className={`svg-icon ${orientation === 'right' ? 'blue' : 'purple'} my-2 my-xl-3 my-xxl-4`}>
                                <use xlinkHref={withPrefix(`sprite.svg#${icon}`)}></use>
                            </svg>
                        </Col>
                        <Col xs={12} md={6} className="info-block__text mx-auto d-flex justify-content-center align-items-center">
                            <div>
                                <h2 className="h1 lined-head mb-3 mb-xl-4 mb-xxl-5 text-center">{t(head)}</h2>
                                <div dangerouslySetInnerHTML={{__html: t(text)}} />
                            </div>
                        </Col>
                    </Row>
                </div>
            </Container>
        </section>
    )
}