import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Col, Container, Row} from "react-bootstrap";

export default function HowOrder() {
    const { t } = useTranslation();
    const steps = [
        {
            name: "howToOrder1",
            text: "howToOrderText1"
        },
        {
            name: "howToOrder2",
            text: "howToOrderText2"
        },
        {
            name: "howToOrder3",
            text: "howToOrderText3"
        },
        {
            name: "howToOrder4",
            text: "howToOrderText4"
        },
        {
            name: "howToOrder5",
            text: "howToOrderText5"
        }
    ];

    return (
        <Container fluid className="how-order px-flex my-1 my-md-2 my-xl-3 pb-3 pb-xxl-4">
            <Row>
                <Col sm={6} md={4} className="my-2 my-xl-3 my-xxl-4">
                    <h2 className="h1">{t('howToOrder')}</h2>
                    <p>{t('howToOrderText', {steps: steps.length})}</p>
                </Col>
                {steps.map((item, index) => {
                    return (
                        <Col sm={6} md={4} key={`technology-${index}`} className="my-2 my-xl-3 my-xxl-4">
                            <div className="how-order__step p-3 p-xl-4 p-xxl-5 d-flex flex-wrap justify-content-between flex-column">
                                <div className="how-order__step-number w-100">0{index + 1}</div>
                                <div>
                                    <div className={`h2 text-uppercase ${item.text ? 'mb-2 mb-xl-3 pb-xxl-1' : 'mb-0'}`}>{t(item.name)}</div>
                                    {item.text &&
                                        <p className="mb-0">{t(item.text)}</p>
                                    }
                                </div>
                            </div>
                        </Col>
                    )
                })}
            </Row>
        </Container>
    )
}