import * as React from "react";
import {useTranslation} from "gatsby-plugin-react-i18next";
import {Col, Container, Row} from "react-bootstrap";
import axios from "axios";
import {withPrefix} from "gatsby";
import InputElement from 'react-input-mask';
import {useState} from "react";
import {checkPhone, clearError} from "./helpers";

export default function HomeContactForm({headText}) {
    const { t } = useTranslation();
    const [name, setName] = React.useState('');
    const [phone, setPhone] = React.useState('');
    const [errorsObj, setErrorsObj] = useState({});

    async function handleSubmit(event) {
        event.preventDefault();
        const dataObj = {
            name: name,
            phone: phone
        };

        axios.post('https://dev.avzpro.com.ua/api/send_form.php', dataObj)
            .then(res => {
                console.log('Returned data:', res.data);
                setName('');
                setPhone('');
            })
            .catch(error => {
                console.log(error);
            });
    }

    return (
        <Container fluid className="contact-form px-flex position-relative d-inline-block">
            <div className="my-3 my-xl-4 my-xxl-5 py-2">
                <h2 className="h1 lined-head mb-3 mb-xl-4 text-center mx-4 mx-md-6 mx-xl-7">{t(headText)}</h2>
                <Row>
                    <Col xs={12} md={6} className="text-center d-flex justify-content-center align-items-center">
                        <form onSubmit={handleSubmit}>
                            <Row className="mb-3 mb-xl-4 mb-xxl-5 mt-xxl-3">
                                <Col xs={12} xl={6}>
                                    <label htmlFor="name" className="w-100 mb-2 mb-xl-3">{t('nameWord')}</label>
                                    <input
                                        id="name"
                                        type="text"
                                        className="text-center mb-2 mb-xl-0"
                                        value={name}
                                        onChange={(e) => setName(e.target.value)}
                                    />
                                </Col>
                                <Col xs={12} xl={6}>
                                    <label htmlFor="phone" className="w-100 mb-2 mb-xl-3 pt-1 pt-xl-0">{t('phoneWord')}</label>
                                    <InputElement id="form-phone"
                                                  className={`text-center ${phone !== '' ? 'has-value' : ''}`}
                                                  mask="+38 (099) 999-99-99"
                                                  value={phone}
                                                  placeholder="+38 (0__) ___-__-__"
                                                  onChange={(e) => setPhone(e.target.value)}
                                                  onFocus={() => setErrorsObj(clearError(errorsObj,'phone'))}
                                                  onBlur={() => setErrorsObj(checkPhone(errorsObj, phone))}
                                    />
                                </Col>
                            </Row>
                            <button type="submit" className="btn__big w-100">{t('orderWord')}</button>
                        </form>
                    </Col>
                    <Col xs={12} md={6} className="d-flex justify-content-center align-items-center mt-3 mt-md-0 pt-1 pt-md-0">
                        <svg className="svg-icon my-2 my-xl-3 my-xxl-4">
                            <use xlinkHref={withPrefix(`sprite.svg#phone-icon`)}></use>
                        </svg>
                    </Col>
                </Row>
            </div>
        </Container>
    )
}